@import "./../../Scss/shared.scss";

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .ant-layout-header {
    color: #000;
    background-color: $blobstationWhite;
    padding-inline: 0px !important;
  }
  .sub-menu-layout {
    .ant-menu-sub {
      background: none !important;
    }
  }
  .build-heading {
    padding: 0;
    margin: 0px 0 10px 0;
    font-weight: 400;
  }

  .ant-breadcrumb-link {
    margin: 0 !important;
    color: #000;
  }

  .logo {
    float: left;
    margin-left: 20px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
  }

  .dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 30vw;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    z-index: 1000;
    margin-top: -20px;
  }

  .products-breadcrumb-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .dropdown-panel {
    padding: 5px;
    width: 100%;
  }
  .inner-dropdown-container {
    display: flex;
    flex-direction: row;
  }

  .breadCrumb-item {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .panel-container {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .panel-products-list {
    padding: 10px;
  }

  .panel-environments-list {
    padding: 10px;
    margin-top: 15%;
    align-items: start;
    justify-content: start;
  }

  .site-layout-content {
    flex: 1;

    .site-layout-content-box {
      min-height: 400px;
      padding: 24px;
      padding-top: 0;
    }
  }

  .common-card {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04), 0 4px 4px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
  }

  .layout-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .layout-header-container {
    background: white;
    border-bottom: 1px solid #eeeff2;
    position: fixed;
    z-index: 20;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px;
  }

  .layout-header-left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .logo {
    cursor: pointer;
    margin-right: 15px;
  }

  .layout-header-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .theme-toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 15px;
  }

  .divider {
    margin-top: 5px;
  }

  .layout-header .ant-breadcrumb {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer {
    background-color: $footer-bg;
    color: $blobstationWhite !important;

    margin-top: auto;
    text-align: center;
    padding: 5px;
  }

  .custom-divider {
    margin: 10px 0;
  }
}

// .ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
//   border-radius: 8px;
//   background-color: #ffffff;
//   border: transparent;
// }

// .ant-notification
//   .ant-notification-notice-wrapper
//   .ant-notification-notice:hover {
//     background-color: #f3f3f3;

//   transition: all 0.4s ease;
// }

// .ant-notification
//   .ant-notification-notice-wrapper
//   .ant-notification-notice-with-icon
//   .ant-notification-notice-message {
//   font-weight: 600;
//   color: #245f9e;
// }

// .ant-notification
//   .ant-notification-notice-wrapper
//   .ant-notification-notice-with-icon
//   .ant-notification-notice-description {
//   font-weight: 600;
//   color: #245f9e;
// }
// .ant-notification-topRight.ant-notification-stack
//   > .ant-notification-notice-wrapper {
//   max-width: 300px;
// }

// .ant-notification
//   .ant-notification-notice-wrapper
//   .ant-notification-notice-icon-error.anticon {
//   color: #245f9e;
// }

.listing-spacing {
  white-space: nowrap;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  object-fit: cover;
}

.common-button {
  display: flex;
  float: right;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
  padding: 8px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.breadcrumb-container.expanded {
  width: 40vw;
  height: auto;
  padding: 8px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 8px 8px 0 0;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.dark-mode .breadcrumb-container.expanded {
  background-color: #171717;
}

.breadcrumb-tag {
  font-size: 14px;
}

.dropdown-wrapper {
  position: absolute;
  z-index: 1;
  width: 40vw;
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 0 8px 8px;
  transition: all 0.3s ease-in-out;
}

.dark-mode .dropdown-wrapper {
  background-color: #171717;
}

.dropdown-search {
  padding: 0px 17px;
}

.dropdown-content {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

.list-column {
  flex: 1;
  transition: flex 0.3s ease;
  display: flex;
  flex-direction: column;
}

.list-column:hover {
  flex: 2;
}

.list-item {
  flex: 1;
  transition: flex 0.3s ease;
  display: flex !important;
  align-items: center;
  justify-content: left !important;
  gap: 10px !important;
  padding: 5px 20px;
  margin: 0;
}

.list-container {
  display: flex;
  flex-direction: row;
  height: 50vh;
  transition: all 0.3s ease;
  width: 100%;
}

.list-wrapper {
  flex: 1;
  transition: flex 0.3s ease;
  overflow-y: auto;
}

.list-item-name {
  flex-grow: 1;
}

.list-item-icon {
  margin-left: 10px;
  color: #1890ff;
}

.list-divider {
  height: 56vh !important;
  margin: 0px !important;
}

.horizontal-divider {
  margin: 0px !important;
}
.list-header {
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  position: sticky;
  padding: 10px 20px;
  top: 0;
  z-index: 1;
}

.dark-mode .list-header {
  color: #fafafa;
}

.ant-list-header {
  padding-block: 0px !important;
  border-block-end: none !important;
}

.product-item,
.env-item {
  flex: 1;
  transition: flex 0.3s ease;
  padding: 8px 16px;
  cursor: pointer;
}

.done-button-container {
  float: right;
  margin-right: 10px;
  margin-top: 10px;
}

.product-card {
  margin-top: 27px;
}
.product-details-card {
  margin-top: 10px;
  display: block;
}

.ant-select-single {
  font-size: 14px;
  height: auto !important;
}
.ant-select-selector {
  cursor: pointer !important;
}
.ant-list-bordered {
  border-radius: 8px !important;
  padding: 10px;
}
.bread-crumb-selection .ant-select-selector {
  width: 100%;
  height: 23px !important;
  display: flex;
  align-items: center !important;
  padding: 0 11px;
  min-width: 100px;
  box-shadow: none !important;
}

.ant-select .ant-select-arrow {
  height: auto !important;
}
.common-form .ant-form-item .ant-form-item-label {
  text-align: start;
}
.common-form-add-pro {
  max-width: 600px;
  width: 100%;
  text-align: start;
  text-align: left;
  justify-content: space-between;
}

.ant-form-item .ant-form-item-label {
  text-align: left;
}
.placeholder-form-item .placeholder-form-item-label {
  text-align: left;
}
.placeholder-form-item-required {
  margin-bottom: 0px !important;
}
.placeholder-form-item-label > label::after {
  content: "" !important;
}
.fs-name {
  color: rgb(0, 0, 0);
  background-color: #efefef;
  padding: 0px 10px;
  height: 24px !important;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.login-form-space {
  width: 100%;
}

.login-form-button {
  padding: 4px 20px;
}

.row-container .ant-divider-vertical {
  height: 100px;
  padding: 0px 10px;
}

.ant-divider-horizontal {
  margin: 5px 0;
}

.label-wapper > label {
  font-size: 14px;
  font-weight: 500;
}

.label-wapper > p {
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 600;
  color: hsl(0, 0%, 0%);
  border-radius: 4px;
}

.discription {
  background-color: white !important;
  font-size: 14px !important;
}

.text-label {
  margin-top: 24px;
}

.tags {
  margin-top: 5px;
}

// ThemeToggleButton.scss

.theme-toggle-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 4px;
  padding: 0px 5px;
  border-radius: 100%;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.theme-toggle-button:hover {
  background-color: #ebedf0;
}

.dark-mode .theme-toggle-button:hover {
  background-color: #333333;
}

.theme-toggle-button.dark-mode {
  color: #f0f0f0; /* Light color for dark mode */
  height: 32px;
  margin: 4px 5px;
}

.theme-toggle-button.light-mode {
  color: #333; /* Dark color for light mode */
  height: 32px;
  margin: 4px 5px;
}

.custom-card {
  border: none;
  width: auto;
  margin-top: 10px;
  max-width: 1100px;
  box-shadow: 1px 1px 10px #d3d3d357;
  margin-bottom: 10px;
}
.ant-col-6 {
  flex: 0 0 25%;
  max-width: 25%;
}

.ant-input-affix-wrapper > input.ant-input {
  font-size: 14px;
}

.ant-table-content {
  box-sizing: border-box;
}

// .ant-divider-vertical {
//   position: relative;
//   top: -0.06em;
//   display: inline-block;
//   height: 0.9em;
//   margin-inline: 8px;
//   margin-block: 0;
//   vertical-align: middle;
//   border-top: 0;
//   border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
//   display: inline;
// }
.label-wapper {
  overflow-wrap: break-word;
  margin-bottom: 5px;
}

.ant-form-item .ant-form-item-control-input {
  max-width: 100%;
  width: 100%;
}

// .breadcrumb-container {
//   width: "100%";
//   margin-left: "1rem";
//   margin-top: "1rem";
//   justify-content: "space-between";
//   display: "flex";
//   flex-direction: "row";
//   align-items: "center";
//   background-color: #fff;
// }

.row-container .ant-divider-vertical {
  height: 100px;
  padding: 0px 10px;
  margin: 13px 1px;
}
// .ant-divider-vertical {
//   height: 35px !important;
//   border-left: 1px solid #e8e8e8;
// }

.build-listing .ant-table-wrapper .ant-table-tbody > tr > td {
  width: fit-content;
}

.top_nav .ant-tabs-nav-list {
  display: block;
  transform: translate(0px, 0px);
}

.user {
  display: flex;
  justify-content: space-between;
  max-width: 20%;
  margin-bottom: 20px;
  flex: 1px 1px 0px;
  align-items: center;
  font-size: 14px;
  width: 100%;
}

.meta-data-string {
  margin-bottom: 10px;
}

.ant-space-item {
  margin: 4px 0;
}
.ant-popover .ant-popover-inner {
  margin: 0 20px 0 0;
}

.ant-dropdown .ant-dropdown-menu {
  margin-right: 20px;
}

.environment {
  display: flex;
  align-items: center;
}

.target-update {
  display: inline-block;
}

@media screen and (max-width: 1116px) {
  .user {
    max-width: 25%;
  }
}

@media screen and (max-width: 1092px) {
  .ant-divider-vertical {
    position: relative;
    margin-inline: 3px;
  }
}

@media screen and (max-width: 992px) {
  .target-row {
    flex-direction: column-reverse;
  }
  .target-bread-crumb {
    display: flex;
    margin: 10px 0 20px 0;
  }
  .target-update {
    display: flex;
    flex-direction: inherit;
  }
  .user {
    max-width: 30%;
  }
  .ant-table-cell {
    width: fit-content;
    max-width: fit-content;
  }
  .ant-col-6 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .listing-spacing {
    max-width: 250px;
    width: 80%;
  }
  .build-listing.ant-table-wrapper .ant-table-tbody > tr > td {
    max-width: 159px;
    width: fit-content;
  }
}
@media screen and (max-width: 800px) {
  .ant-space-align-baseline {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  // .ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  //   position: absolute;
  //   top: 12px;
  // }

  .ant-popover .ant-popover-inner {
    max-width: 200px;
    margin: 0 20px 0 0;
  }
  .submit-btn {
    margin-top: 0;
  }
  .ant-space-align-center {
    align-items: baseline;
  }

  .user-box {
    margin: 0;
    min-height: fit-content;
    max-width: fit-content;
  }

  .user-line {
    border-bottom: 1px solid rgb(219, 216, 216);
    margin-bottom: 5px;
  }

  .user {
    max-width: 40%;
  }
  .ant-col-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-container .ant-divider-vertical {
    display: none;
  }

  .listing-spacing {
    max-width: 200px;
    width: 80%;
  }

  .ant-form-item .ant-form-item-control-input {
    max-width: inherit;
  }
  .build-listing.ant-table-wrapper .ant-table-tbody > tr > td {
    max-width: 120px;
    width: fit-content;
  }

  // .ant-notification-topRight.ant-notification-stack
  //   > .ant-notification-notice-wrapper {
  //   max-width: 250px;
  //   padding: 00px;
  //   margin: 0;
  // }

  // .ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  //   padding: 10px 14px;
  // }

  .bread-Crumb-Container {
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: block;
    min-width: 100%;
  }
  .layout {
    overflow: hidden;
  }

  .ant-select,
  .css-dev-only-do-not-override-pr0fja
    .ant-select-single
    .ant-select-show-arrow {
    width: 100%;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
  }

  .ant-breadcrumb {
    margin-bottom: 0px;
  }
  .login-card {
    max-width: fit-content;
    width: 100%;
    flex: none;
    padding: 20px;
  }

  .bread-Crumb-Container {
    overflow-wrap: break-word;
  }
}

@media screen and (max-width: 680px) {
  .submit-btn {
    margin-top: 0;
  }
  .build-listing.ant-table-wrapper .ant-table-tbody > tr > td {
    max-width: max-content;
    width: fit-content;
  }
}
@media screen and (max-width: 600px) {
  .submit-btn {
    margin-top: 0;
  }
  .meta-data-string {
    display: block;
  }
  .user {
    max-width: 45%;
  }
  .listing-spacing {
    max-width: 150px;
    width: 80%;
  }
  .test .ant-space-gap-col-small {
    column-gap: 8px;
    flex-direction: column;
    grid-gap: 0;
  }
  .test-page-input-width {
    min-width: 270px !important;
    max-width: 270x !important;
  }
  .ant-space-item {
    margin: 4px 0;
  }

  .ant-tabs-top
    > .ant-tabs-nav
    .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
    opacity: 0;
  }

  .ant-tabs-nav-listment.style {
    transform: translate(0px, 0px);
  }
}

@media screen and (max-width: 575px) {
  .submit-btn {
    margin-top: 0;
  }
  .layout {
    width: 100%;
    max-width: 100%;
  }
  .layout .site-layout-content .site-layout-content-box {
    width: 100%;
    max-width: initial;
  }
  .common-form {
    max-width: 100%;
    width: 100%;
  }
  .row-container .ant-divider-vertical {
    display: none;
    height: 100px;
    padding: 0px 10px;
  }

  .ant-form-item .ant-form-item-control-input {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 559px) {
  .datepicker-box {
    inset: 801.8px auto auto 14px;
  }

  .user {
    max-width: 53%;
  }
  .listing-spacing {
    max-width: 100px;
    width: 80%;
  }
  .ant-tabs-nav-listment.style {
    transform: translate(0px, 0px);
  }
  //   .ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap, :where(.css-dev-only-do-not-override-xu9wm8).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-wrap {
  //     position: relative;
  //     transform: translate(0);
  // }
}

@media screen and (max-width: 468px) {
  .ant-picker-active-bar {
    display: none;
  }
  .target-update {
    display: inline-block;
  }
  .user {
    max-width: 60%;
  }
  .target-bread-crumb {
    display: flex;
    margin: 10px 0 0px 0;
  }
  .ant-form-item .ant-form-item-label > label {
    font-size: 15px;
  }
  .ant-layout * {
    margin-left: 0;
  }
  .row-container .ant-divider-vertical {
    display: none;
  }
  .layout {
    overflow: hidden;
  }
  .row-container {
    flex-direction: block;
  }

  .ant-form-item .ant-form-item-control-input {
    max-width: 100%;
    width: 100%;
  }
  .btn_box {
    max-width: fit-content;
  }

  .row-container .ant-col {
    width: 100%;
    max-width: 100%;
  }
}

.content-rollout-data-container {
  height: 66.5vh;
  max-height: 66.5vh;
  overflow-y: scroll;
}
.build-data-container {
  height: 72vh;
  max-height: 72vh;
  overflow-y: scroll;
  border: 1px solid #eeeff2;
  border-radius: 8px;
}
.release-data-container {
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #eeeff2;
  padding: 5px;
  border-radius: 8px;
}

.meta-data-container {
  overflow-y: scroll;
  border: 1px solid #eeeff2;
  border-radius: 8px;
}

.target-data-container {
  border: 1px solid #eeeff2;
  border-radius: 8px;
}
.target-criteria-container {
  // min-height: 180px;
  // overflow-y: scroll;
  border: 1px solid #eeeff2;
  border-radius: 8px;
}
.feature-data-container {
  height: 65vh;
  overflow-y: scroll;
  border: 1px solid #eeeff2;
  padding: 5px;
  border-radius: 8px;
}
.json-editor-drawer-body {
  padding: 0px !important;
}
@media screen and (max-width: 405px) {
  .ant-picker-active-bar {
    display: none;
  }
  .user {
    max-width: 80%;
  }
  .ant-select-selector {
    width: 100%;
    max-width: 100%;
  }

  .ant-form-item .ant-form-item-control-input-content {
    width: 100%;
    max-width: 100%;
  }
}

