.text-center {
  text-align: center;
}

.common-tag-color {
  background-color: #c4dcf6;
  color: black;
  margin: 1px;
}
.common-tag-color-inactive {
  background-color: #9b9ea2;
  color: #fff;
}
.common-error {
  color: red;
}
.content-placeholder-table-cell {
  padding: 5px 5px !important;
}

.users-selection .ant-form-item-control {
  max-width: 100% !important;
}

.react-query-builder-input-string {
  width: 100%;
  max-width: 80%;
  min-width: 200px;
}
.react-query-builder-input-number {
  width: 100%;
  min-width: 200px;
}
.build-rollout-max-width {
  max-width: 300px !important;
}
.target-max-width {
  max-width: 300px !important;
}
.build-target-max-width {
  min-width: 300px !important;
}
.ruleGroup-combinators {
  .ant-select {
    max-width: 80px !important;
  }
}
.test-page-input-width {
  min-width: 280px !important;
  max-width: 280px !important;
}
.test-page-select-width {
  min-width: 200px !important;
  max-width: 200px !important;
}
.select-items-copy-feature {
  width: 200px !important;
}

.divider-with-tag {
  margin-top: 0px !important;
  margin-bottom: 12px !important;
  .tag {
    font-size: 14px;
    line-height: 25px;
  }
}
.tag-info {
  display: flex;
  gap: 5px;
}
.select-min-width {
  min-width: 100px;
}
.queryBuilder-invalid {
  color: red;
}

.queryBuilder-branches .ruleGroup {
  border-color: #0d056b;
  box-shadow: 0px 1px 1px 0px gray;
}

.ant-select.ant-select-in-form-item {
  width: 100%;
}

.queryBuilder-branches {
  background-color: hsl(0, 0%, 100%);
  border-radius: 5px;
  width: 100%;
}

.ant-btn.ant-btn-icon-only {
  margin: 0;
}

.deco-card-padding {
  width: 100%;
  padding: 5px;
  .ant-card-body {
    padding: 5px;
  }
}
.deco-ant-form-item-no-bottom-margin {
  margin-bottom: 0px;
}
@media screen and (max-width: 767px) {
  .ant-tag-volcano {
    margin-top: 5px;
  }
  .react-query-builder-input-string {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .queryBuilder-branches .ruleGroup .rule {
    display: block;
  }
  .ant-space-compact {
    margin-right: 5px;
  }
  .ant-btn {
    margin: 5px 0 0 5px;
  }
  .ant-btn-default {
    margin: 0px 5px 0px 0;
  }

  .queryBuilder-branches .ruleGroup .ruleGroup-header,
  .ruleGroup .rule {
    display: inline-block;
    overflow-wrap: break-word;
  }
}

.submitRelease {
  margin-top: 0;
}

@media screen and (max-width: 575px) {
  .ant-form-item .ant-form-item-control-input {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 405px) {
  .build-rollout-max-width {
    max-width: 270px !important;
  }
  .target-max-width {
    max-width: 270px !important;
  }
  .build-target-max-width {
    min-width: 270px !important;
  }
  .react-query-builder-input-string {
    width: 100%;
    min-width: 100%;
    max-width: fit-content;
  }
  .ant-select-selector {
    max-width: 273px;
    width: 100%;
    height: 100%;
    padding: 0 11px;
  }
  .ant-input {
    max-width: 100%;
    width: 100%;
  }
  .queryBuilder-branches {
    max-width: 100%;
    width: 100%;
  }
  .build-target-max-width {
    width: 100%;
    box-sizing: border-box;
    max-width: 280px !important;
  }
}

.ruleGroup {
  background: white !important;
  border-color: #f0f0f0 !important;
}
.ruleGroup,
.ruleGroup .ruleGroup,
.ruleGroup .rule {
}
.ruleGroup .rule {
  background-color: white;
}
.ruleGroup .ruleGroup {
}
.ruleGroup-remove,
.rule-remove {
}
.ruleGroup-combinators,
.ruleGroup-addRule,
.ruleGroup-addGroup,
.rule-fields,
.rule-operators,
.rule-value {
  background-color: white;
}
.ruleGroup-remove,
.rule-remove {
}

.log-fields {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-area {
  min-height: 280px;
  background: #fff;
}

.target-rollout-form .ant-space-item {
  width: 100%;
}

.select-target-min-width {
  min-width: 150px !important;
  max-width: 150px !important;
}
.jsoneditor-react-container {
  width: 100%;
  min-height: 50vh;
}

.ace_editor {
  min-height: 50vh !important;
}

.jsoneditor-form {
  width: 100%;
  height: 100%;
}

.feature-flag-editor-container {
  display: flex;
  flex-direction: row;
  height: 70vh;
  width: 100%;
  gap: 10px;
}

.feature-rollout-editor-container {
  display: flex;
  flex-direction: row-reverse;
  min-height: 40vh;
  width: 100%;
  gap: 10px;
}
.feature-rollout-copy-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.copy-btn-text {
  font-size: 18px;
}
.je-object__container {
  padding-bottom: 10px;
}
.level-1 {
  display: none !important;
}
.btn-group {
  display: flex !important;
  gap: 10px !important;
}
.feature-flag-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -10px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: rgba(165, 165, 165, 0.726);
}
.info-text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
.feature-flag-btn-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
}
.card-title > span {
  font-size: 18px !important;
  font-size: 500 !important;
  text-transform: capitalize;
}
.form-group {
  margin-bottom: 5px;
}
.card-title {
  margin-bottom: 5px;
  font-size: 1rem;
}
.btn-sm {
  padding: 2px 6px;
  font-size: 0.7rem;
  border-radius: 0.2rem !important;
}
.card-body {
  padding: 0.6rem 0.6rem !important;
  margin-bottom: 5px !important;
}

.add-dropdown-placeholder-item-row {
  flex-direction: column;
}
.monaco-editor-card-body {
  height: 100%;
}
.btn-sm {
  padding: 2px 6px;
  font-size: 0.7rem;
  border-radius: 0.2rem !important;
}
.enabled-form-item-row {
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
.enabled-form-item-label {
  display: flex;
  align-items: center;
  padding: 0px;
}
.product-user-card-body {
  padding: 8px 12px !important;
}
.enabled-form-item-label label {
  margin-bottom: 0px !important;
}
.full-access-label {
  margin-bottom: 0px !important;
}
.full-access-label label {
  margin-bottom: 0px !important;
}
.main-card .ant-form-item {
  margin-bottom: 0px !important;
}
.main-card .ant-form-item-no-colon {
  margin-bottom: 0px !important;
}
.enabled-form-item-label > label::after {
  content: "" !important;
}
.card-body {
  padding: 0.6rem 0.6rem !important;
  margin-bottom: 5px !important;
}
.placeholder-dropdown-space-item {
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .feature-flag-editor-container {
    flex-direction: column;
  }
  .feature-rollout-editor-container {
    flex-direction: column-reverse;
  }
}

.target-criteria-item-has-error {
  border: 1px dashed #ff4d4f !important;
  color: #ff4d4f !important;
}

.required-field {
  color: #ff4d4f;
}
.editor-alert-info {
  text-align: center;
  font-size: 18px;
}
.hidden-editor-alert {
  display: none;
}
.rollout {
  display: none;
}

.feature-collapse {
  border-color: #91caff !important;
}
.feature-collapse-header {
  background: #e6f4ff;
  border-radius: 20px !important;
  padding: 3px 16px !important;
}
.feature-collapse-content-box {
  padding: 3px 16px !important;
}

.add-content-placeholder {
  width: 100%;
  padding: 5px;
}
.add-content-placeholder-select {
  width: 100%;
}
.add-placeholder-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  align-items: center;
  font-weight: 600;
}
.action-buttons {
  cursor: pointer;
}
.content-placeholder-delete-button {
  background-color: red;
}
.content-placeholder-delete-button:hover {
  background-color: red !important;
}
.long-character-text {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.long-character-text-name {
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-Placeholder-container {
  height: 58vh;
  overflow-y: auto; /* Use 'auto' for better performance */
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
}
.submit-btn-placeholder-item {
  display: flex;
  margin-bottom: 0px !important;
  justify-content: end;
}
.content-rollout-copy-submit-item {
  display: flex;
  margin-bottom: 0px !important;
  justify-content: end;
  gap: 10px;
}
.common-modal-footer-buttons-item {
  display: flex;
  margin-bottom: 0px !important;
  justify-content: end;
  gap: 10px;
}
.scroll-btn-placeholder-item-control-input-content {
  display: flex;
  justify-content: end;
}
.add-dropdown-placeholder-item {
  margin-bottom: 0px !important;
}
.button-danger {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.button-danger:hover {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}
.content-placeholder-divider {
  height: auto;
}
.color-icon-red {
  color: #ff4d4f;
}

.content-rollout-placeholder-icons {
  font-size: 17px;
}
.editable-cell-form-item {
  margin-bottom: 0px !important;
}
.placeholder-image-url-container {
  display: flex;
  gap: 10px;
}

.content-rollout-card-min-height {
  min-height: 69vh;
}

.editor-copy-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
}

.monaco-editor .monaco-editor-overlaymessage .message {
  width: 20vw;
}
.placeholder-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
// .save-icon {
//   color: #1677ff;
// }
// .cancel-icon {
//   color: #ff4d4f;
// }

/* EditorPreview.scss */
.preview-dynamic-40vh {
  max-height: 40vh;
}
.preview-dynamic-80vh {
  max-height: 80vh;
}
.editor-preview {
  &-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8em;
  }

  &-modal {
    &-body {
      height: 40vh;
      &.fullscreen {
        height: 80vh;
      }
    }
    &-container {
      width: 50vw;
      &.fullscreen {
        width: 100vw;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &-preview {
    overflow: scroll;
    background-color: #f0f2f5;
    padding: 16px;
  }
}

.justify-button-and-center {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-bottom: 0.8em;
  font-weight: 600;
}

.rollout-grid-layout {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); // Responsive columns
  gap: 10px;
}

.rollout-grid-layout-two-col {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(260px, 1fr)
  ); // Responsive columns
  gap: 10px;
}

// AddNotificationTemplatesComponent.scss
.card-container {
  .deco-card-padding {
    padding: 16px;
  }
  .sider-close-btn {
    padding: 0;
  }
}
.Delete-icon {
  position: absolute !important;
  top: 16px;
  right: 16px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}
.margin-zero {
  margin-bottom: 0 !important;
}
.min-height-card-container {
  min-height: 74vh;
}
.min-height-card {
  min-height: 69vh;
}
.card-style-none {
  border: none;
  padding: 0;
  margin: 0;
}
.card-style-none .ant-card-body {
  padding: 0 !important;
}
.content-language-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

// Styles for the editor and placeholder table
.editor-container {
  display: flex;
  flex: 1 1;
  height: 60vh;
  margin-bottom: 52px;

  .editor {
    flex: 1;
    margin-right: 10px;
  }
  .editor-heading {
    font-weight: 600;
    padding: 18px;
    padding-top: 8px;
    padding-left: 0px;
  }

  .placeholder-table {
    flex: 1;
  }
}
.content-info-item {
  margin: 4px 0;
}
.content-info-gap-row-small {
  row-gap: 0px !important;
}
.add-language-field {
  width: "100%";
  display: "flex";
  flex-direction: "column";
  .ant-space-item {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.template-max-width {
  width: 100%;
}

.template-content-button-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 20px;
}
.content-template-container {
  gap: 20px;
}
.sider-container {
  background: #fff !important;
}

.custom-color-picker {
  border-radius: 0.375rem;
  overflow: hidden;
}
.custom-form-container {
  overflow: scroll;
  height: 50vh;
}
.rollout-form {
  overflow: scroll;
  height: 50vh;
}
.noChange-language-tab-container-tab-active
  > .noChange-language-tab-container-tab-btn {
  color: gray !important;
}
.noChange-language-tab-container-ink-bar {
  background: gray !important;
}
.noSave-language-tab-container-tab-active
  > .noSave-language-tab-container-tab-btn {
  color: red !important;
}
.noSave-language-tab-container-ink-bar {
  background: red !important;
}
.save-language-tab-container-tab-active > .save-language-tab-container-tab-btn {
  color: green !important;
}
.save-language-tab-container-ink-bar {
  background: green !important;
}
.save-language-label-container {
  color: green !important;
}
.noChange-language-label-container {
  color: gray !important;
}

.content-cfc-collapse {
  border-radius: 20px;
  margin-bottom: 20px;
  border-color: #91caff !important;
}
.content-cfc-collapse-header {
  background: #e6f4ff;
  border-radius: 20px !important;
}

.image-container {
  position: relative;
  display: inline-block;
}

.icon-overlay {
  position: absolute;
  top: 10px;
  right: 3px;
  border-radius: 50%;
  padding: 2px 3px;
  cursor: pointer;
}

.upload-cdn-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 10px;
}
.uploader-placeholder-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.copy-popover {
  width: 400px;
}
.checkbox-split-input-outlined:focus-within {
  border-color: transparent !important;
  box-shadow: none !important;
}
@media (min-width: 1600px) {
  .custom-flex-row {
    flex-direction: row !important;
    align-items: center;
  }
}

/* 1. 729px - 799px */
@media (min-height: 729px) and (max-height: 799px) {
  .responsive-height {
    min-height: 72vh;
    max-height: 72vh;
  }
}

/* 2. 800px - 849px */
@media (min-height: 800px) and (max-height: 849px) {
  .responsive-height {
    min-height: 75vh;
    max-height: 75vh;
  }
}

/* 3. 850px - 899px */
@media (min-height: 850px) and (max-height: 899px) {
  .responsive-height {
    min-height: 78vh;
    max-height: 78vh;
  }
}

/* 4. 900px - 949px */
@media (min-height: 900px) and (max-height: 949px) {
  .ace_editor {
    min-height: 58vh !important;
  }
  .responsive-height {
    min-height: 78vh;
    max-height: 78vh;
  }
}

/* 5. 950px - 999px */
@media (min-height: 950px) and (max-height: 999px) {
  .responsive-height {
    min-height: 83vh;
    max-height: 83vh;
  }
}

/* 6. 1000px - 1049px */
@media (min-height: 1000px) and (max-height: 1049px) {
  .responsive-height {
    min-height: 86vh;
    max-height: 86vh;
  }
}

/* 7. 1050px - 1099px */
@media (min-height: 1050px) and (max-height: 1099px) {
  .responsive-height {
    min-height: 89vh;
    max-height: 89vh;
  }
}

/* 8. 1100px - 1149px */
@media (min-height: 1100px) and (max-height: 1149px) {
  .responsive-height {
    min-height: 92vh;
    max-height: 92vh;
  }
}

